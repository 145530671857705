import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React from 'react'

import logo from './logo.png'

function Copyright() {
  return (
    <Typography variant="body1">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Blue Fox Studios
      </Link>{' '}
      {new Date().getFullYear()}.
    </Typography>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  logo: {
    width: '400px',
  },
  main: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    height: 'calc(100vh - 92px)',
  },
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: 'auto',
    backgroundColor: theme.palette.grey[200],
    height: '92px',
    textAlign: 'center',
  },
  link: {
    color: '#00aad4',
  },
}))

const App = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Container component="main" className={classes.main} maxWidth="sm">
        <Container component="header" className={classes.header}>
          <img src={logo} className={classes.logo} alt="Blue Fox Studios logo" />
        </Container>
        <Typography variant="h5" component="h2" gutterBottom>
          We are currently working on our projects. More information coming soon. For immediate requests, please contact{' '}
          <a className={classes.link} href="mailto:contact@bfstudios.de">
            contact@bfstudios.de
          </a>
          .
        </Typography>
      </Container>
      <footer className={classes.footer}>
        <Container maxWidth="sm">
          <Typography variant="body1">
            Contact:{' '}
            <a className={classes.link} href="mailto:contact@bfstudios.de">
              contact@bfstudios.de
            </a>
          </Typography>
          <Copyright />
        </Container>
      </footer>
    </div>
  )
}

export default App
